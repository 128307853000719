import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loading from '../Components/Loading';

export const WithFetchJsonContent = ({
  reduxInStore,
  addToReduxFunction,
  jsonLocation,
  children,
}) => {
  const dispatch = useDispatch();
  const [hasData, setHasData] = useState();

  useEffect(() => {
    const controller = new AbortController();
    if (Object.keys(reduxInStore).length === 0) {
      fetch(`/json/${jsonLocation}.json`, { signal: controller.signal })
        .then((response) => {
          if (!response.ok) throw new Error(`HTTP error, status = ${response.status}`);
          return response.json();
        })
        .then((json) => {
          addToReduxFunction(dispatch, json);
        })
        .catch((error) => {
          console.error(`Error: ${error.message}`);
        })
        .finally(() => {
          setHasData(true);
        });
    } else {
      setHasData(true);
    }
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxInStore, dispatch]);

  return hasData ? children : <Loading />;
};
