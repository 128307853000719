import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { IoImages } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import Icon from '../Components/Common/Icon';
import { LineThrough } from '../Components/Common/LineThrough';
import SavePark from '../Components/Common/SavePark';
import { ParkTabs } from '../Components/Park/ParkTabs';
import { ParkGalleryModal } from '../Components/Parks/ParkGalleryModal';
import { useHandleModalState } from '../Hooks/useHandleModalState';
import { useRemoveBrokenImages } from '../Hooks/useRemoveBrokenImages';
import { useScrollToTop } from '../Hooks/useScrollToTop';
import { getConfigSelector, getParkSelector } from '../Redux/selectors';
import { parkNameWithoutOperator } from '../utils';

function Park() {
  useScrollToTop();
  const { pid } = useParams();
  const parkData = useSelector(getParkSelector(pid));
  const config = useSelector(getConfigSelector);

  const { show, onHide, onOpen } = useHandleModalState();

  const { ADD1, ADD2, COUNTY, POSTCODE, PARKNAME, PARKDESC, OPERATOR, imgUrls } = parkData;

  const { validImages } = useRemoveBrokenImages(imgUrls);

  const parkImgs = validImages?.map((img, i) => (
    <img key={i} src={img} alt={PARKNAME} onClick={onOpen} className="cursor-pointer" />
  ));

  const numberOfImages = parkImgs?.length;

  const formatAddressComponent = (component) =>
    component
      ?.split(',')
      .map((part) => part.trim())
      .join(', ') || '';

  const addressText = [ADD1, ADD2, COUNTY, POSTCODE]
    .filter(Boolean)
    .map((str) => formatAddressComponent(str))
    .join(', ');

  return (
    <>
      <Helmet>
        <title>{`${PARKNAME}${COUNTY ? `, ${COUNTY}` : ''} - Club £${config.promotion} Holidays`}</title>
        <meta name="description" content={`${PARKDESC.replace(/<[^>]*>?/gm, '')}`} />
        <body className="bg-white" />
      </Helmet>

      <Container fluid className="bg-light">
        <Container>
          <Row className="align-items-center">
            <Col xs={6} md={2}>
              <Link to="/parks" className="text-decoration-none" title="Go Back">
                <Icon icon="left" variant="dark" size="200" />
              </Link>
            </Col>
            <Col xs={12} md={8} className="py-3 py-md-5 text-center order-last">
              <h1 className="mb-0">{parkNameWithoutOperator(PARKNAME, OPERATOR)}</h1>
            </Col>
            <Col xs={6} md={2} className="text-right order-md-last">
              <SavePark pid={pid} showLabel />
            </Col>
          </Row>

          <LineThrough>
            <div className="px-3 px-md-5">
              <span className="font-weight-bold">{OPERATOR}</span>
              <br />
              {addressText}
            </div>
          </LineThrough>

          <Row className="mb-5">
            <Col lg={6}>
              <div className="slider-container mb-3 mb-lg-0">
                <Slider
                  {...{ dots: false, infinite: true, speed: 500, lazyLoad: true }}
                  className="gallery-slider border-radius"
                >
                  {parkImgs}
                </Slider>
                {numberOfImages > 0 && (
                  <div className="slider-overlay px-3" onClick={onOpen}>
                    <span className="pr-2" style={{ marginBottom: '3px' }}>
                      <IoImages />
                    </span>
                    <p>{numberOfImages}</p>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={6} className="pl-lg-5 lead park-desc" dangerouslySetInnerHTML={{ __html: PARKDESC }} />
          </Row>
        </Container>
      </Container>

      <ParkTabs />

      {show && <ParkGalleryModal show={show} onHide={onHide} />}
    </>
  );
}

export default Park;
