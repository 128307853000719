import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TABS_CONTENT_SPACING } from '../../const';
import { getParkSelector } from '../../Redux/selectors';
import Icon from '../Common/Icon';

export const Travel = () => {
  const { pid } = useParams();
  const parkData = useSelector(getParkSelector(pid));

  const { ADD1, COUNTY, POSTCODE, PARKNAME, COUNTRY, MAPSPLACEID, PARK_DIRECTIONS_CONTENT } = parkData;

  const googleMapAddress = [PARKNAME, ADD1, COUNTY, COUNTRY, POSTCODE].filter(Boolean).join(', ');

  return (
    <Row className={TABS_CONTENT_SPACING}>
      <Col md={6}>
        <div
          dangerouslySetInnerHTML={{
            __html: PARK_DIRECTIONS_CONTENT,
          }}
        />
        <Button
          href={`https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${encodeURIComponent(
            googleMapAddress,
          )}&destination_place_id=${MAPSPLACEID}`}
          target="_blank"
          rel="nopener noreferrer"
          className="mb-3"
        >
          <Icon icon="pin" variant="white" /> Get Directions
        </Button>
      </Col>
      <Col md={6} className="text-center">
        <iframe
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyATBTfplImdF2OFchQAPQIoU4GPONlEZyc&zoom=11&q=${encodeURIComponent(
            googleMapAddress,
          )}`}
          width="100%"
          height="450"
          style={{ border: 0 }}
          title={`Map to ${PARKNAME}`}
          allowFullScreen
          className="border-radius"
        />
      </Col>
    </Row>
  );
};
