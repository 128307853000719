import React from 'react';
import { useSelector } from 'react-redux';
import { addHowItWorksData } from '../Redux/actions';
import { getHowItWorksDataSelector } from '../Redux/selectors';
import { WithFetchJsonContent } from './WithFetchJsonContent';

function WithHowItWorks({ children }) {
  const howItWorksData = useSelector(getHowItWorksDataSelector);

  return (
    <WithFetchJsonContent
      reduxInStore={howItWorksData}
      addToReduxFunction={addHowItWorksData}
      jsonLocation="content/HIW"
    >
      {children}
    </WithFetchJsonContent>
  );
}

export default WithHowItWorks;
