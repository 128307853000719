import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

export const isBookingOpen = (config) => {
  const now = Date.now();
  return now > Date.parse(config.bookingOpenFrom) && now < Date.parse(config.bookingOpenUntil);
};

export const applyFilters = (parks, filters) => {
  return parks.filter((park) => {
    return Object.entries(filters).every(([key, value]) => {
      return value === 'All' || park[key]?.trim() === value?.trim();
    });
  });
};

export function parkNameWithoutOperator(parkName, operator) {
  return parkName.replace(operator, '').trim() || parkName.trim();
}

export const transformHtmlWithLinks = (htmlString) => {
  const transform = (node) => {
    if (node.type === 'tag' && node.name === 'a') {
      const url = node.attribs.href;
      const isExternal = url.startsWith('http') || url.startsWith('//');

      if (isExternal) {
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {node.children[0]?.data}
          </a>
        );
      } else if (url.startsWith('mailto:')) {
        // Mailto links
        return <a href={url}>{node.children[0]?.data}</a>;
      }

      return <Link to={url}>{node.children[0]?.data}</Link>;
    }
  };

  return parse(htmlString, { replace: transform });
};
