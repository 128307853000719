import React from 'react';
import { useSelector } from 'react-redux';
import { addFerriesData } from '../Redux/actions';
import { ferriesDataSelector } from '../Redux/selectors';
import { WithFetchJsonContent } from './WithFetchJsonContent';

function WithFerriesData({ children }) {
  const hasFerryData = useSelector(ferriesDataSelector);

  return (
    <WithFetchJsonContent reduxInStore={hasFerryData} addToReduxFunction={addFerriesData} jsonLocation="content/ferries">
      {children}
    </WithFetchJsonContent>
  );
}

export default WithFerriesData;
