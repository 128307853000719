import {
  ADD_CONFIG,
  ADD_FAQDATA,
  ADD_FERRIES_DATA,
  ADD_HOMEDATA,
  ADD_PARK,
  ADD_SAVEDPARK,
  ADD_THEPARKS,
  ADD_ATTRACTIONS,
  ADD_HOW_IT_WORKS_DATA,
  ADD_TERMS_DATA,
} from './action-types';

const initialState = {
  Attractions: [],
  config: {},
  FAQ: {},
  Ferries: [],
  Home: [],
  Parks: {},
  SavedParks: localStorage.getItem('SavedParks') ? JSON.parse(localStorage.getItem('SavedParks')) : [],
  TheParks: [],
  howItWorksData: {},
  terms: {},
};

const addImgUrlsToPark = (data, pid) => {
  const imgsValues = data.PARKIMGS;

  const folder = pid.substring(0, 1);

  const imgUrls = imgsValues?.map(
    (img) =>
      `//content.breakfreeholidays.co.uk/images/parks/${folder}/${pid}/gallery/${pid}-${String(img).padStart(
        2,
        '0',
      )}.jpg`,
  );
  return { ...data, imgUrls };
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONFIG:
      return {
        ...state,
        config: action.payload,
      };
    case ADD_ATTRACTIONS:
      return {
        ...state,
        Attractions: action.payload.attractions,
      };
    case ADD_FERRIES_DATA:
      return {
        ...state,
        Ferries: action.payload.ferries,
      };
    case ADD_HOMEDATA:
      return {
        ...state,
        Home: action.payload,
      };
    case ADD_FAQDATA:
      return {
        ...state,
        FAQ: action.payload,
      };
    case ADD_HOW_IT_WORKS_DATA:
      return {
        ...state,
        howItWorksData: action.payload,
      };
    case ADD_TERMS_DATA:
      return {
        ...state,
        terms: action.payload.terms,
      };
    case ADD_THEPARKS:
      return {
        ...state,
        TheParks: action.payload,
      };
    case ADD_PARK:
      return {
        ...state,
        Parks: {
          ...state.Parks,
          [action.pid]: addImgUrlsToPark(action.data, action.pid),
        },
      };
    case ADD_SAVEDPARK:
      if (state.SavedParks.includes(action.data)) {
        const y = state.SavedParks.filter((x) => x !== action.data);
        localStorage.setItem('SavedParks', JSON.stringify(y));

        return {
          ...state,
          SavedParks: y,
        };
      } else {
        const y = [...state.SavedParks, action.data];
        localStorage.setItem('SavedParks', JSON.stringify(y));

        return {
          ...state,
          SavedParks: y,
        };
      }
    default:
      return state;
  }
};

export default Reducer;
