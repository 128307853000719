
import React from 'react';
import { useSelector } from 'react-redux';
import { addTermsData } from '../Redux/actions';
import { getTermsDataSelector } from '../Redux/selectors';
import { WithFetchJsonContent } from './WithFetchJsonContent';

function WithTermsData({ children }) {
  const termsData = useSelector(getTermsDataSelector);

  return (
    <WithFetchJsonContent reduxInStore={termsData} addToReduxFunction={addTermsData} jsonLocation="content/terms">
      {children}
    </WithFetchJsonContent>
  );
}

export default WithTermsData;
