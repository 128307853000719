import {
  ADD_CONFIG,
  ADD_FAQDATA,
  ADD_FERRIES_DATA,
  ADD_HOMEDATA,
  ADD_PARK,
  ADD_SAVEDPARK,
  ADD_THEPARKS,
  ADD_ATTRACTIONS,
  ADD_HOW_IT_WORKS_DATA,
  ADD_TERMS_DATA
} from './action-types';

export const addConfig = (dispatch, json) => {
  return dispatch({
    type: ADD_CONFIG,
    payload: json,
  });
};

export const addTheParks = (dispatch, json) => {
  return dispatch({
    type: ADD_THEPARKS,
    payload: json,
  });
};

export const addPark = (dispatch, pid, data) => {
  return dispatch({
    type: ADD_PARK,
    pid,
    data,
  });
};

export const addSavedPark = (dispatch, pid) => {
  return dispatch({
    type: ADD_SAVEDPARK,
    data: pid,
  });
};

export const addAttractions = (dispatch, json) => {
  return dispatch({
    type: ADD_ATTRACTIONS,
    payload: json,
  });
};

export const addFerriesData = (dispatch, json) => {
  return dispatch({
    type: ADD_FERRIES_DATA,
    payload: json,
  });
};

export const addHomeData = (dispatch, json) => {
  return dispatch({
    type: ADD_HOMEDATA,
    payload: json,
  });
};

export const addFaqData = (dispatch, json) => {
  return dispatch({
    type: ADD_FAQDATA,
    payload: json,
  });
};

export const addHowItWorksData = (dispatch, json) => {
  return dispatch({
    type: ADD_HOW_IT_WORKS_DATA,
    payload: json,
  });
};

export const addTermsData = (dispatch, json) => {
  return dispatch({
    type: ADD_TERMS_DATA,
    payload: json,
  });
};
