import { Col, Container, Row } from 'react-bootstrap';
import { TABS_HEADING_SPACING } from '../../const';

export const TabLayout = ({ heading, id, subheading, children }) => (
  <Container className={TABS_HEADING_SPACING} id={id}>
    <Row>
      <Col>
        <h2 className="h3">{heading}</h2>
        {subheading}
      </Col>
    </Row>
    {children}
  </Container>
);
