import { lazy, Suspense } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.scss';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Loading from './Components/Loading';
import WithAttractions from './HOC/WithAttractions';
import WithConfig from './HOC/WithConfig';
import WithFaqData from './HOC/WithFaqData';
import WithFerriesData from './HOC/WithFerriesData.js';
import WithHomeData from './HOC/WithHomeData';
import WithHowItWorks from './HOC/WithHowItWorks.js';
import { WithParkFound } from './HOC/WithParkFound.js';
import WithParks from './HOC/WithParks.js';
import Attractions from './Pages/Attractions';
import { Ferries } from './Pages/Ferries.js';
import Park from './Pages/Park.js';
import WithTermsData from './HOC/WithTermsData.js';

function App() {
  const Home = lazy(() => import('./Pages/Home'));
  const WithParkData = lazy(() => import('./HOC/WithParkData'));
  const Parks = lazy(() => import('./Pages/Parks'));
  const HowItWorks = lazy(() => import('./Pages/HowItWorks'));
  const Faq = lazy(() => import('./Pages/FAQ'));
  const NotFound = lazy(() => import('./Pages/404'));
  const Terms = lazy(() => import('./Pages/Terms'));
  const ContentTemplate = lazy(() => import('./Pages/ContentTemplate'));

  return (
    <Router>
      <WithConfig>
        <div className="app">
          <Header />
          <main>
            <div className="content">
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Route path="/" exact>
                    <WithHomeData>
                      <Home />
                    </WithHomeData>
                  </Route>
                  <Route path="/parks">
                    <WithParks>
                      <Parks />
                    </WithParks>
                  </Route>
                  <Route path="/park/:pid">
                    <WithAttractions>
                      <WithParkData>
                        <WithParkFound>
                          <Park />
                        </WithParkFound>
                      </WithParkData>
                    </WithAttractions>
                  </Route>
                  <Route path="/attractions" exact>
                    <WithAttractions>
                      <Attractions />
                    </WithAttractions>
                  </Route>
                  <Route path="/how-it-works/:scrollToContent?" exact>
                    <WithHowItWorks>
                      <HowItWorks />
                    </WithHowItWorks>
                  </Route>
                  <Route path="/ferries">
                    <WithFerriesData>
                      <Ferries />
                    </WithFerriesData>
                  </Route>
                  <Route path="/faq">
                    <WithFaqData>
                      <Faq />
                    </WithFaqData>
                  </Route>
                  <Route path="/terms">
                    <WithTermsData>
                      <Terms />
                    </WithTermsData>
                  </Route>
                  <Route path="/page/:contents" exact>
                    <ContentTemplate />
                  </Route>
                  <Route path="/404">
                    <NotFound />
                  </Route>
                  <Route path="/howitworks" render={() => <Redirect to="/how-it-works" />} />
                  <Route path="/contact-us" render={() => <Redirect to="/page/contact-us" />} />
                  <Route path="/contactus" render={() => <Redirect to="/page/contact-us" />} />
                  <Route path="/medical-health" render={() => <Redirect to="/page/medical-health" />} />
                  <Route path="/europe-travel" render={() => <Redirect to="/page/europe-travel" />} />
                  <Route path="/form">
                    <NotFound />
                  </Route>
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              </Suspense>
            </div>
            <Footer />
          </main>
        </div>
      </WithConfig>
    </Router>
  );
}

export default App;
