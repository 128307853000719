import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FerryCard } from '../Components/Ferries/FerryCard';
import { ferriesDataSelector } from '../Redux/selectors';

export const Ferries = () => {
  const ferriesData = useSelector(ferriesDataSelector);

  return (
    <Container className="pt-5">
      <Row>
        <Col>
          <h1>Ferry Good Deals... Book Now!</h1>
          <p className="mb-0">
            Book our great ferry offers online now. The best crossing times and rates are sure to fill quickly so
            don&apos;t delay! You&apos;ll need your Sun Holiday booking number from your confirmation email. Simply
            click on the route that suits you best, enter your booking number, follow the instructions, and pay by card.
            Happy Hols! On board accommodation and all vehicle supplements at public fare. Subject to availability.
            Valid for selected departures during 2025 only. Supplements of up to £10 apply for phone bookings.
          </p>
        </Col>
      </Row>
      <Row xs={1}>
        {ferriesData.map(({ title, image, content, buttonText, buttonUrl }) => (
          <Col key={title} className="pt-5">
            <FerryCard title={title} image={image} content={content} buttonText={buttonText} buttonUrl={buttonUrl} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
