import React from 'react';
import { useSelector } from 'react-redux';
import { addConfig } from '../Redux/actions';
import { getConfigSelector } from '../Redux/selectors';
import { WithFetchJsonContent } from './WithFetchJsonContent';

function WithConfig({ children }) {
  const hasConfig = useSelector(getConfigSelector);

  return (
    <WithFetchJsonContent reduxInStore={hasConfig} addToReduxFunction={addConfig} jsonLocation="content/config">
      {children}
    </WithFetchJsonContent>
  );
}

export default WithConfig;
