import React from 'react';
import { useSelector } from 'react-redux';
import { addFaqData } from '../Redux/actions';
import { hasFaqSelector } from '../Redux/selectors';
import { WithFetchJsonContent } from './WithFetchJsonContent';

function WithFaqData({ children }) {
  const hasFaq = useSelector(hasFaqSelector);

  return (
    <WithFetchJsonContent reduxInStore={hasFaq} addToReduxFunction={addFaqData} jsonLocation="content/faq">
      {children}
    </WithFetchJsonContent>
  );
}

export default WithFaqData;
