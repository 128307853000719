import React from 'react';
import { useSelector } from 'react-redux';
import { addHomeData } from '../Redux/actions';
import { homeDataSelector } from '../Redux/selectors';
import { WithFetchJsonContent } from './WithFetchJsonContent';

function WithHomeData({ children }) {
  const homeData = useSelector(homeDataSelector);

  return (
    <WithFetchJsonContent
      reduxInStore={homeData}
      addToReduxFunction={addHomeData}
      jsonLocation="content/homepageColumns"
    >
      {children}
    </WithFetchJsonContent>
  );
}

export default WithHomeData;
