import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getParkSelector } from '../../Redux/selectors';
import { Accommodation } from './Accommodation';
import { Attractions } from './Attractions';
import { Facilities } from './Facilities';
import { Travel } from './Travel';
import { Video } from './Video';
import { Charges } from './Charges';

export const useParkTabConfigurations = () => {
  const { pid } = useParams();
  const parkData = useSelector(getParkSelector(pid));

  const { PARK_CHARGES_CONTENT, ATTRACTIONS, VIDEO } = parkData;

  const showCharges = PARK_CHARGES_CONTENT !== null;

  const tabConfigurations = [
    {
      title: 'Facilities',
      content: <Facilities />,
      condition: true,
    },
    {
      title: 'Video',
      content: <Video videoUrl={VIDEO} />,
      condition: VIDEO?.startsWith('http'),
    },
    {
      title: 'Travel & Directions',
      content: <Travel />,
      condition: true,
    },
    {
      title: 'Accommodation',
      content: <Accommodation />,
      condition: true,
    },
    {
      title: 'Attractions',
      content: <Attractions />,
      condition: ATTRACTIONS.length > 0,
    },
    {
      title: 'Charges',
      content: <Charges />,
      condition: showCharges,
    },
  ];

  const availableTabs = tabConfigurations.filter(({ condition }) => condition);
  const tabsWithEventKey = availableTabs.map((item) => ({
    ...item,
    eventKey: item.title.toLowerCase().split(' ')[0],
    anchor: item.title.toLowerCase().split(' ').join('-'),
  }));

  const firstEventKey = tabsWithEventKey?.[0]?.eventKey;

  return { tabConfigurations: tabsWithEventKey, firstEventKey };
};
