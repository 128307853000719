import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import bfLogo from '../../Assets/images/bf-logo-dark.svg';
import { getConfigSelector } from '../../Redux/selectors';
import { isBookingOpen } from '../../utils';

const LINK_STYLES = 'p-3 site-font text-white text-uppercase';

const ExternalLink = ({ href, children }) => (
  <a href={href} rel="noopener noreferrer" target="_blank" className={LINK_STYLES}>
    {children}
  </a>
);
const InternalLink = (props) => <NavLink className={LINK_STYLES} {...props} />;

function Footer() {
  const config = useSelector(getConfigSelector);

  return (
    <>
      <Container fluid className="p-0 mt-5">
        <div className="waves dark" />
      </Container>
      <Container as="footer" fluid className="footer bg-dark py-5">
        <Container className="cls-min-height">
          <Row>
            <Col xs={12} lg={3} className="d-flex flex-column">
              <div className="text-center">
                <img
                  src="https://content.breakfreeholidays.co.uk/images/club950/sunlogowhite2.png"
                  alt="The Sun"
                  style={{ width: '100px', height: '66px' }}
                />
              </div>
              <div className="my-auto text-center d-none d-lg-block">
                {isBookingOpen(config) && ( // TODO: Make book now button reusable?
                  <a
                    href="https://www.ukholidaysinthesun.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary mt-4 mt-lg-0"
                  >
                    Book Now
                  </a>
                )}
              </div>
              <div className="mt-auto text-center">
                <a href="https://thesuntravel.breakfreeholidays.co.uk" target="_blank" rel="noreferrer noopener">
                  <img src={bfLogo} alt="Breakfree Holidays" style={{ width: '100px', height: '23.95px' }} />
                </a>
              </div>
            </Col>
            <Col xs={12} lg={7} className="mt-4 mt-lg-0">
              <Row xs={1}>
                <Col className="d-flex flex-wrap justify-content-center">
                  <InternalLink to="/">Home</InternalLink>
                  <InternalLink to="/parks">The Parks</InternalLink>
                  <InternalLink to="/how-it-works">How it Works</InternalLink>
                  <InternalLink to="/faq">FAQ</InternalLink>
                  <ExternalLink href="https://thesuntravel.breakfreeholidays.co.uk/inspiration">
                    Inspiration
                  </ExternalLink>
                  <InternalLink to="/terms">T&amp;C</InternalLink>
                  <ExternalLink href="https://newsprivacy.co.uk">Cookie Settings</ExternalLink>
                </Col>
                <Col className="py-2 px-2">
                  <small className="text-white">
                    &copy; News Group Newspapers Limited in England No. 679215 Registered office: 1 London Bridge
                    Street, London, SE1 9GF. &quot;The Sun&quot;, &quot;Sun&quot;, &quot;Sun Online&quot; are registered
                    trademarks or trade names of News Group Newspapers Limited. This service is provided on News Group
                    Newspapers&apos; Limited&apos;s{' '}
                    <a
                      href="https://www.thesun.co.uk/terms-and-conditions/"
                      className="text-warning"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Standard Terms and Conditions
                    </a>{' '}
                    in accordance with our{' '}
                    <a
                      href="http://www.newsprivacy.co.uk"
                      className="text-warning"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Privacy &amp; Cookie Policy
                    </a>{' '}
                    . To inquire about a licence to reproduce material, visit our{' '}
                    <a
                      href="https://www.thesun.co.uk/syndication/"
                      className="text-warning"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Syndication site
                    </a>
                    . View our online Press Pack. For other inquiries,{' '}
                    <a
                      className="text-warning"
                      href="https://www.thesun.co.uk/contact-us/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Contact Us
                    </a>
                    .
                  </small>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="text-center" />
          </Row>

          <Row />
          <Row>
            <Col className="text-center text-light" />
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Footer;
