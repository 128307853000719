import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  binaryArrayIntoIndexedAndFilteredItems,
  facilityData,
  integerToBinaryArrayOfMinlength,
} from '../../Data/exports';
import { getParkSelector } from '../../Redux/selectors';
import { TABS_CONTENT_SPACING } from '../../const';

// Block [Cafe, Disco, Jacuzzi, Snooker/Pool, Supermarket]
const HIDDEN_FACILITIES = [3, 7, 11, 18, 20];

export const Facilities = () => {
  const { pid } = useParams();
  const parkData = useSelector(getParkSelector(pid));

  const { FACILITIES, PARK_FACILITIES_CONTENT } = parkData;
  const parkFacilitiesBulletPoints = PARK_FACILITIES_CONTENT?.split('|');
  const hasBulletFacilities = parkFacilitiesBulletPoints.length > 0;

  const facilitiesBinaryArray = integerToBinaryArrayOfMinlength(FACILITIES, 25);
  const facilitiesIndexes = binaryArrayIntoIndexedAndFilteredItems(facilitiesBinaryArray, HIDDEN_FACILITIES);

  const hasIconFacilities = facilitiesIndexes.length > 0;

  return (
    <Row xs={1} lg={2} className={TABS_CONTENT_SPACING}>
      {hasBulletFacilities && (
        <Col className="pb-4">
          <Row xs={1} sm={2} lg={1}>
            {parkFacilitiesBulletPoints.map((facility, i) => (
              <Col key={i}>
                <li dangerouslySetInnerHTML={{ __html: facility }} />
              </Col>
            ))}
          </Row>
        </Col>
      )}
      {hasIconFacilities && (
        <Col>
          <Row xs={1} sm={2}>
            {facilitiesIndexes.map((i) => {
              const [key, text] = facilityData[i];
              return (
                <Col key={i} className="d-flex align-items-center pb-1">
                  <span className="icon-font size-300 mr-2" data-char={key}>
                    {key}
                  </span>
                  {text}
                </Col>
              );
            })}
          </Row>
        </Col>
      )}
    </Row>
  );
};
