export const attractionsSelector = (state) => state.appState.Attractions;
export const getAttractionSelector = (attractionID) => (state) => state.appState.Attractions[attractionID];
export const theParksSelector = (state) => state.appState.TheParks;
export const getParkSelector = (pid) => (state) => state.appState.Parks[pid];
export const getSavedParksSelector = (state) => state.appState.SavedParks;
export const homeDataSelector = (state) => state.appState.Home;
export const ferriesDataSelector = (state) => state.appState.Ferries;
export const faqDataSelector = (state) => state.appState.FAQ.faqs;
export const hasFaqSelector = (state) => state.appState.FAQ;
export const getConfigSelector = (state) => state.appState.config;
export const getTermsDataSelector = (state) => state.appState.terms;
export const getHowItWorksDataSelector = (state) => state.appState.howItWorksData;
export const getCancellationProtectionDataSelector = (state) => state.appState.howItWorksData?.cancellationProtection;
export const getAccommodationDataSelector = (state) => state.appState.howItWorksData?.accommodation;
export const getExtrasDataSelector = (state) => state.appState.howItWorksData?.extras;
export const getHowItWorksCardsDataSelector = (state) => state.appState.howItWorksData?.howItWorksCards;
