import { Row, Col } from 'react-bootstrap';

export const FerryCard = ({ title, image, content, buttonText, buttonUrl }) => {
  const hasUrl = Boolean(buttonText && buttonUrl);
  const imageComponent = <img className="w-100" src={image} alt={title} />;

  return (
    <Row>
      <Col xs={12} md={5}>
        {hasUrl ? (
          <a href={buttonUrl} target="_blank" rel="noopener noreferrer">
            {imageComponent}
          </a>
        ) : (
          imageComponent
        )}
      </Col>
      <Col>
        <h2 className="mt-3 mt-md-0">{title}</h2>
        <p dangerouslySetInnerHTML={{ __html: content }} />

        {hasUrl && (
          <a
            href={buttonUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary btn-block btn-lg marginTop"
          >
            {buttonText}
          </a>
        )}
      </Col>
    </Row>
  );
};
