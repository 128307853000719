import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavsHelpers } from '../../Hooks/useNavsHelpers';
import { useParkTabConfigurations } from './useParkTabConfigurations';
import { TabLayout } from '../Common/TabLayout';

export const ParkTabs = () => {
  const { tabConfigurations } = useParkTabConfigurations();

  const { activeNav, handleNavClick } = useNavsHelpers(tabConfigurations);

  return (
    <>
      <div className="header-nav">
        <Container>
          <ul className="d-flex w-100 mb-0 pl-0">
            {tabConfigurations.map(({ title, anchor }, i) => {
              const active = anchor === activeNav;
              return (
                <li
                  key={i}
                  onClick={() => handleNavClick(anchor)}
                  className={`p-2 cursor-pointer header-nav-item ${active ? 'active' : ''}`}
                >
                  <p className="mb-0">{title}</p>
                </li>
              );
            })}
          </ul>
        </Container>
      </div>

      {tabConfigurations.map(({ content, anchor, title, }) => (
        <TabLayout key={anchor} id={anchor} heading={title}>
          {content}
        </TabLayout>
      ))}
    </>
  );
};
