import React from 'react';
import { useSelector } from 'react-redux';
import { addTheParks } from '../Redux/actions';
import { theParksSelector } from '../Redux/selectors';
import { WithFetchJsonContent } from './WithFetchJsonContent';

function WithParks({ children }) {
  const parksData = useSelector(theParksSelector);

  return (
    <WithFetchJsonContent reduxInStore={parksData} addToReduxFunction={addTheParks} jsonLocation="PARKLIST">
      {children}
    </WithFetchJsonContent>
  );
}

export default WithParks;
