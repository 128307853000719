import React from 'react';
import { useSelector } from 'react-redux';
import { addAttractions } from '../Redux/actions';
import { attractionsSelector } from '../Redux/selectors';
import { WithFetchJsonContent } from './WithFetchJsonContent';

function WithAttractions({ children }) {
  const allAttractions = useSelector(attractionsSelector);

  return (
    <WithFetchJsonContent reduxInStore={allAttractions} addToReduxFunction={addAttractions} jsonLocation="ATTRACTIONS">
      {children}
    </WithFetchJsonContent>
  );
}

export default WithAttractions;
