export const ADD_THEPARKS = 'ADD_THEPARKS';
export const ADD_PARK = 'ADD_PARK';
export const ADD_SAVEDPARK = 'ADD_SAVEDPARK';
export const ADD_FERRIES_DATA = 'ADD_FERRIES_DATA';
export const ADD_HOMEDATA = 'ADD_HOMEDATA';
export const ADD_FAQDATA = 'ADD_FAQDATA';
export const ADD_CONFIG = 'ADD_CONFIG';
export const ADD_ATTRACTIONS = 'ADD_ATTRACTIONS';
export const ADD_HOW_IT_WORKS_DATA = 'ADD_HOW_IT_WORKS_DATA';
export const ADD_TERMS_DATA = 'ADD_TERMS_DATA';
