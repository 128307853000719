import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TABS_CONTENT_SPACING } from '../../const';
import { getParkSelector } from '../../Redux/selectors';

export const Charges = () => {
  const { pid } = useParams();
  const parkData = useSelector(getParkSelector(pid));

  const { PARK_CHARGES_CONTENT } = parkData;
  return (
    <Row xs={1} lg={2} className={TABS_CONTENT_SPACING}>
      <Col>
        <div dangerouslySetInnerHTML={{ __html: PARK_CHARGES_CONTENT }} />
      </Col>
    </Row>
  );
};
