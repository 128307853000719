import { useCallback, useEffect, useRef, useState } from 'react';

const getStickyNavbarsHeight = () => {
  const header = document.querySelector('.navbar');
  const subNav = document.querySelector('.header-nav');

  const headerHeight = header ? header.offsetHeight : 0;
  const subNavHeight = subNav ? subNav.offsetHeight : 0;
  return headerHeight + subNavHeight;
};

export const useNavsHelpers = (accordionItems) => {
  const [totalStickyHeight, setTotalStickyHeight] = useState(0);

  const [activeNav, setActiveNav] = useState(accordionItems[0].anchor);
  const isProgrammaticScroll = useRef(false);

  useEffect(() => {
    setTotalStickyHeight(getStickyNavbarsHeight());
  }, []);

  const handleNavClick = useCallback(
    (id, behavior = 'smooth') => {
      const element = document.getElementById(id);
      if (!element) return;

      isProgrammaticScroll.current = true;

      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - totalStickyHeight;

      let onScrollCompletion;
      let timeoutId;

      const checkScrollCompletion = () => {
        if (
          Math.abs(window.scrollY - (offsetPosition + 16)) <= 1 ||
          window.innerHeight + window.scrollY >= document.body.offsetHeight 
        ) {
          cleanup();
        }
      };

      const cleanup = () => {
        window.removeEventListener('scroll', onScrollCompletion);
        clearTimeout(timeoutId);
        isProgrammaticScroll.current = false;
      };

      onScrollCompletion = () => checkScrollCompletion();
      window.addEventListener('scroll', onScrollCompletion);

      // Timeout fallback in case the scroll never reaches the exact position
      timeoutId = setTimeout(cleanup, 1000);

      window.scrollTo({
        top: offsetPosition + 16,
        behavior,
      });

      setActiveNav(id);
    },
    [totalStickyHeight],
  );

  useEffect(() => {
    const handleScroll = () => {
      if (isProgrammaticScroll.current) return;

      const scrollPosition = window.scrollY || window.pageYOffset;

      accordionItems.forEach((section) => {
        const element = document.getElementById(section.anchor);
        if (!element) return;

        const adjustedOffsetTop = element.offsetTop - totalStickyHeight;

        if (scrollPosition >= adjustedOffsetTop && scrollPosition < adjustedOffsetTop + element.offsetHeight) {
          setActiveNav(section.anchor);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalStickyHeight]);

  useEffect(() => {
    const navContainer = document.querySelector('.header-nav ul');
    const activeNavElement = document.querySelector('.header-nav-item.active');

    if (!(navContainer && activeNavElement)) return;

    const containerRect = navContainer.getBoundingClientRect();
    const activeRect = activeNavElement.getBoundingClientRect();

    const isFullyVisible = activeRect.left >= containerRect.left && activeRect.right <= containerRect.right;

    if (!isFullyVisible) {
      activeNavElement.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
    }
  }, [activeNav]);

  return { activeNav, handleNavClick, totalStickyHeight };
};
