import { Col, Row } from 'react-bootstrap';
import { TABS_CONTENT_SPACING } from '../../const';

export const Video = ({ videoUrl }) => {
  const videoAttributes = '?byline=0&title=0&transparent=0';

  return (
    <Row className={`video-container px-sm-4 ${TABS_CONTENT_SPACING}`}>
      <Col>
        <iframe src={videoUrl + videoAttributes} title="Video" />
      </Col>
    </Row>
  );
};
