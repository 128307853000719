import { useEffect, useState } from 'react';

export const useRemoveBrokenImages = (images) => {
  const [validImages, setValidImages] = useState([]);

  const filterValidImages = async (imgUrls) => {
    const checkImage = (url) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve({ url, valid: true });
        img.onerror = () => resolve({ url, valid: false });
      });
    };

    try {
      const results = await Promise.all(imgUrls.map(checkImage));
      return results.filter(({ valid }) => valid).map(({ url }) => url);
    } catch (error) {
      console.error('Error filtering images:', error);
      return [];
    }
  };

  useEffect(() => {
    let isMounted = true;

    const filterImages = async () => {
      if (!images || images.length === 0 || !isMounted) return;
      const filtered = await filterValidImages(images);
      setValidImages(filtered);
    };

    filterImages();

    return () => isMounted = false;
  }, [images]);

  return { validImages };
};
